/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PaymentProject = {
    amount?: number;
    color?: string;
    id?: string;
    name?: string;
    status?: PaymentProject.status;
};
export namespace PaymentProject {
    export enum status {
        ACTIVE = 'active',
        INACTIVE = 'inactive',
    }
}

