/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BusinessAccount } from './BusinessAccount';
import type { Document } from './Document';
import type { InvoicePaymentHistory } from './InvoicePaymentHistory';
import type { InvoicePdfHistory } from './InvoicePdfHistory';
import type { InvoiceStatusHistory } from './InvoiceStatusHistory';
import type { LineItem } from './LineItem';
import type { Project } from './Project';
import type { Section } from './Section';
export type Invoice = {
    balance_due?: number;
    converted_at?: string;
    created_at?: string;
    date?: string;
    disable_card_payment?: boolean;
    discount_amount?: number;
    discount_applied?: number;
    discount_percent?: number;
    documents?: Array<Document>;
    due_date?: string;
    estimate?: boolean;
    estimate_status?: Invoice.estimate_status;
    full_payee?: BusinessAccount;
    full_payor?: BusinessAccount;
    id?: string;
    internal_notes?: string;
    invoice_source?: string;
    last_updated_at?: string;
    line_items?: Array<LineItem>;
    line_items_subtotal?: number;
    markup_applied?: number;
    markup_percent?: number;
    /**
     * card payment fields
     */
    merchant_pays_interchange?: boolean;
    number?: string;
    payee_id?: string;
    payment_history?: Array<InvoicePaymentHistory>;
    payment_request_max?: number;
    payor_id?: string;
    pdf_history?: Array<InvoicePdfHistory>;
    pdf_version?: string;
    pending_payments?: number;
    projects?: Array<Project>;
    public_notes?: string;
    sales_tax_percent?: number;
    sections?: Array<Section>;
    service_description?: string;
    settled_payments?: number;
    shipping_amount?: number;
    shipping_taxable?: boolean;
    status?: Invoice.status;
    status_history?: Array<InvoiceStatusHistory>;
    tax_applied?: number;
    terms_and_conditions?: string;
    total_amount?: number;
};
export namespace Invoice {
    export enum estimate_status {
        DRAFT = 'draft',
        PENDING = 'pending',
        APPROVED = 'approved',
        REJECTED = 'rejected',
        INVOICED = 'invoiced',
    }
    export enum status {
        OPEN = 'open',
        PENDING = 'pending',
        PAID = 'paid',
        PARTIAL_PAID = 'partial_paid',
        CANCELED = 'canceled',
    }
}

