import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/types/types-external";
import { BusinessConfiguration, ConfigurationService } from "../../generated/openapi";
import { RootState, StatefulEntity } from "../../store";
import { returnWithErrorWrap } from "../../store/error";
import { createInitialState, error, pending } from "../../store/reducer";
import { resetStore } from "../../store/slice";

export enum BusinessConfigKey {
  EXTERNAL_INVOICE_NUMBER = "EXTERNAL_INVOICE_NUMBER",
  LOGO = "LOGO",
  DCA_URL = "DCA_URL",
  CHECKBOOK_MAXIMUM_AMOUNT = "CHECKBOOK_MAXIMUM_AMOUNT",
}
export type BusinessConfig = Partial<Record<BusinessConfigKey, BusinessConfiguration>>;

export const getBusinessConfigurationItem = createAsyncThunk<
  BusinessConfiguration,
  { businessAccountId: string; key: BusinessConfigKey }
>("business/configuration/get", async (args, thunk) =>
  returnWithErrorWrap(
    () => ConfigurationService.getBusinessConfigurationItem(args.businessAccountId, args.key),
    thunk
  )
);
export const updateBusinessConfigurationItem = createAsyncThunk<
  BusinessConfiguration,
  { businessAccountId: string; data: BusinessConfiguration }
>("business/configuration/update", async (args, thunk) =>
  returnWithErrorWrap(
    () => ConfigurationService.putBusinessConfiguration(args.data, args.businessAccountId),
    thunk
  )
);
export const deleteBusinessConfigurationItem = createAsyncThunk<
  any,
  { businessAccountId: string; key: BusinessConfigKey }
>("business/configuration/delete", async (args, thunk) =>
  returnWithErrorWrap(
    () => ConfigurationService.deleteBusinessConfigurationItem(args.businessAccountId, args.key),
    thunk
  )
);

export const getConfigurationItem = createAsyncThunk<
  BusinessConfiguration,
  { key: BusinessConfigKey }
>("business/globalConfiguration/get", async (args, thunk) =>
  returnWithErrorWrap(() => ConfigurationService.getConfigurationItem(args.key), thunk)
);

export const selectBusinessConfiguration = (state: RootState) => state.businessConfig;
export const selectBusinessConfigurationItem = (key: BusinessConfigKey) => (state: RootState) =>
  state.businessConfig.data?.[key];

// Updates store with config item in response.
const updateConfigItem = (state: WritableDraft<StatefulEntity<BusinessConfig>>, action: any) => {
  state.loading = false;
  state.data[action.meta.arg.key] = action.payload;
  state.fulfilled = Date.now();
  state.empty = false;
};

const businessConfigSlice = createSlice({
  name: "businessConfig",
  initialState: createInitialState<BusinessConfig>({}),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBusinessConfigurationItem.pending, pending);
    builder.addCase(getBusinessConfigurationItem.fulfilled, updateConfigItem);
    builder.addCase(getBusinessConfigurationItem.rejected, error);

    builder.addCase(updateBusinessConfigurationItem.pending, pending);
    builder.addCase(updateBusinessConfigurationItem.fulfilled, updateConfigItem);
    builder.addCase(updateBusinessConfigurationItem.rejected, error);

    builder.addCase(deleteBusinessConfigurationItem.pending, pending);
    builder.addCase(deleteBusinessConfigurationItem.fulfilled, (state, action) => {
      state.loading = false;
      state.fulfilled = Date.now();
      delete state.data[action.meta.arg.key];
      state.empty = Object.keys(state.data).length === 0;
    });
    builder.addCase(deleteBusinessConfigurationItem.rejected, error);

    builder.addCase(getConfigurationItem.pending, pending);
    builder.addCase(getConfigurationItem.fulfilled, updateConfigItem);
    builder.addCase(getConfigurationItem.rejected, error);

    builder.addCase(resetStore, () => createInitialState<BusinessConfig>({}));
  },
});

export default businessConfigSlice.reducer;
