/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeleteResponse } from '../models/DeleteResponse';
import type { Plan } from '../models/Plan';
import type { SubscriptionResponse } from '../models/SubscriptionResponse';
import type { SubscriptionUpdateRequest } from '../models/SubscriptionUpdateRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SubscriptionService {
    /**
     * Get business account subscription detail
     * @param id ID of the business account
     * @returns SubscriptionResponse OK
     * @throws ApiError
     */
    public static getSubscription(
        id: string,
    ): CancelablePromise<SubscriptionResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/subscription',
            path: {
                'id': id,
            },
            errors: {
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Update business account subscription
     * @param id ID of the business account
     * @param data Subscription update
     * @returns SubscriptionResponse OK
     * @throws ApiError
     */
    public static updateSubscription(
        id: string,
        data: SubscriptionUpdateRequest,
    ): CancelablePromise<SubscriptionResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/business-account/{id}/subscription',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Add an addon to an existing subscription
     * @param id ID of the business account
     * @param addon name of the addon
     * @returns SubscriptionResponse OK
     * @throws ApiError
     */
    public static addAddonToSubscription(
        id: string,
        addon: string,
    ): CancelablePromise<SubscriptionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/subscription/addon/{addon}',
            path: {
                'id': id,
                'addon': addon,
            },
            errors: {
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Get available plans
     * @returns Plan OK
     * @throws ApiError
     */
    public static getPlans(): CancelablePromise<Array<Plan>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/plans',
            errors: {
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Get plan by name
     * @param planId ID of the plan
     * @returns Plan OK
     * @throws ApiError
     */
    public static getPlan(
        planId: string,
    ): CancelablePromise<Plan> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/plans/{planId}',
            path: {
                'planId': planId,
            },
            errors: {
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Add an addon to an existing subscription
     * @param id ID of the business account
     * @param addon name of the addon
     * @returns DeleteResponse OK
     * @throws ApiError
     */
    public static removeAddonFromSubscriptionV2(
        id: string,
        addon: string,
    ): CancelablePromise<DeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v2/business-account/{id}/subscription/addon/{addon}',
            path: {
                'id': id,
                'addon': addon,
            },
            errors: {
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }
}
