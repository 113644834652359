import { FlowIdentifier, Tokens } from "@okta/okta-auth-js";
import OktaSignIn, { WidgetOptions } from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/css/okta-sign-in.min.css";
import { logSentryError } from "flume/sentry";
import { useEffectOnce } from "flume/shared/hook";
import { useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { ConfigurationService } from "../../generated/openapi/services/ConfigurationService";
import { useI18n } from "../../i18n";
import { BusinessConfigKey } from "../business/businessConfigSlice";
import { OktaSignInFallback } from "./OktaSignInFallback";
import { RegistrationForm } from "./type";
import { setInputValue } from "./util";

interface OktaSignInWidgetProps {
  config: WidgetOptions;
  initial?: RegistrationForm;
  onError: (reason: any) => PromiseLike<never> | void;
  onSuccess: (value: Tokens) => Tokens | PromiseLike<Tokens> | void;
  register?: boolean;
  hideUserRegistration?: boolean;
}

/**
 *
 * @description -- Handles calling Okta to render the widget.  Adds customizations after the widget has rendered.
 *
 */
export const OktaSignInWidget = ({
  config,
  initial,
  onSuccess,
  onError,
  register = false,
  hideUserRegistration = true,
}: OktaSignInWidgetProps) => {
  const widgetRef = useRef();
  const [queryParams] = useSearchParams();
  const t = useI18n({ namespaces: ["module/login"], prefix: "okta" });

  useEffectOnce(
    () => widgetRef.current,
    () => {
      const localConfig = {
        ...config,
        otp: queryParams.get("otp"),
        state: queryParams.get("state"),
        ...(register ? { flow: "signup" as FlowIdentifier } : {}),
      };
      const widget = new OktaSignIn(localConfig);

      widget.on("afterRender", (data) => {
        const view = data.controller;
        if (view === "mfa-verify") {
          (document.getElementsByClassName("sms-request-button")[0] as HTMLElement).click();
        } else if (view === "primary-auth") {
          if (hideUserRegistration) {
            const footerInfo = document.getElementsByClassName("footer-info")[0] as HTMLElement;
            if (!footerInfo) return;
            footerInfo.remove();
          } else {
            const signupInfo = document.getElementsByClassName("signup-info")[0] as HTMLElement;
            if (!signupInfo) return;
            signupInfo.innerHTML = `<span>${t(
              "needAccount"
            )}</span><span class="signup-link"><a href="https://apply.wexinc.com/SelfRegister?pgm=wexselectfuel" target="_blank" class="link">${t(
              "dcaApply"
            )}</a></span>.`;
            const dcaUrl = ConfigurationService.getConfigurationItem(BusinessConfigKey.DCA_URL);
            dcaUrl
              .then((response) => {
                signupInfo.innerHTML = `<span>${t(
                  "needAccount"
                )}</span><span class="signup-link"><a href="${
                  response.value
                }" target="_blank" class="link">${t("dcaApply")}</a></span>.`;
              })
              .catch((error) => {
                logSentryError(error);
              });
          }
        } else if (data.formName === "enroll-profile" && register) {
          initial?.email && setInputValue("email", initial?.email);
          initial?.firstName && setInputValue("firstName", initial?.firstName);
          initial?.lastName && setInputValue("lastName", initial?.lastName);
        }
      });

      widget
        .showSignInToGetTokens({
          el: widgetRef.current,
        })
        .then(onSuccess)
        .catch(onError);

      return () => {
        widget.remove();
        widget.off();
      };
    },
    [config, initial, queryParams, onSuccess, onError, register, hideUserRegistration, t]
  );

  return <OktaSignInFallback className="okta-layout-container" ref={widgetRef} />;
};

export default OktaSignInWidget;
