import { ErrorBoundary } from "@sentry/react";
import deepmerge from "deepmerge";
import { useMemo } from "react";
import Lottie from "react-lottie";
export interface LottieAnimationOptionalProps extends React.HTMLAttributes<SVGElement> {
  height?: number | string;
  options?: Object;
  width?: number | string;
}

interface LottieAnimationProps extends LottieAnimationOptionalProps {
  animation: Object;
}

const LottieAnimation = ({
  animation,
  height = 200,
  options,
  width = 200,
  ...rest
}: LottieAnimationProps) => {
  const defaultOptions = useMemo(
    () => ({
      loop: true,
      autoplay: true,
      animationData: animation,
      rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
    }),
    [animation]
  );
  const opts = useMemo(() => deepmerge(defaultOptions, options || {}), [defaultOptions, options]);
  return (
    <ErrorBoundary fallback={<></>}>
      <Lottie options={opts} height={height} width={width} {...rest} />
    </ErrorBoundary>
  );
};

export default LottieAnimation;
