import { Tokens } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { useBodyClass } from "flume/component/hook";
import { logSentryError } from "flume/sentry";
import { Suspense, useCallback } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import config from "../../config";
import { getPath, routes } from "../../http/route";
import { Storage } from "../../shared/storage";
import { useRegistrationFormDefaults } from "./hook";
import "./okta.scss";
import { OktaSignInFallback } from "./OktaSignInFallback";
import OktaSignInWidget from "./OktaSignInWidget";
interface OktaSignInWidgetControllerProps {
  register?: boolean;
}

/**
 *
 * @param register -- Determines whether the form will show the login or registration form.
 * This component uses the hooks to pull the auth information from Okta.
 * Will also pull query parameters to pre-populate the form fields in registration mode.
 * Redirects the user on to the main application if the user is already authenticated.
 */
export const OktaSignInWidgetController = ({
  register = false,
}: OktaSignInWidgetControllerProps) => {
  useBodyClass("okta");
  const { oktaAuth, authState } = useOktaAuth();
  const [queryParams] = useSearchParams();

  const handleSuccess = useCallback(
    (tokens: Tokens) => {
      Storage.setLastLogin(new Date().toISOString());
      oktaAuth.handleLoginRedirect(tokens);
    },
    [oktaAuth]
  );
  const queryParamDefaults = useRegistrationFormDefaults(queryParams);

  if (!authState) {
    return <OktaSignInFallback />;
  }

  return authState.isAuthenticated ? (
    <Navigate to={getPath(routes.app)} replace />
  ) : (
    <Suspense fallback={<OktaSignInFallback />}>
      <OktaSignInWidget
        config={config.auth}
        initial={queryParamDefaults}
        onSuccess={handleSuccess}
        onError={logSentryError}
        register={register}
        hideUserRegistration={config.hideUserRegistration}
      />
    </Suspense>
  );
};
