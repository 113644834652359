/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Naics } from '../models/Naics';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class NaicsService {
    /**
     * Find naics codes from fuzz or code
     * @param fuzz 3 or more character space separated words, or numeric code
     * @param code naics search code
     * @param limit number of results to return, not to exceed 100
     * @param page page number to return
     * @returns Naics OK
     * @throws ApiError
     */
    public static naicsSearch(
        fuzz?: string,
        code?: string,
        limit?: number,
        page?: number,
    ): CancelablePromise<Array<Naics>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/naics',
            query: {
                'fuzz': fuzz,
                'code': code,
                'limit': limit,
                'page': page,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
