/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeleteResponse } from '../models/DeleteResponse';
import type { NetworkAccount } from '../models/NetworkAccount';
import type { NetworkProject } from '../models/NetworkProject';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BusinessAccountNetworkService {
    /**
     * Gets the list of network entitites for a given business.
     * @param id ID of the business account
     * @param skip number of results to skip, used for pagination
     * @param number Number of network entities to get
     * @param projectId field to filter by project id
     * @returns NetworkAccount OK
     * @throws ApiError
     */
    public static getNetwork(
        id: string,
        skip?: number,
        number?: number,
        projectId?: string,
    ): CancelablePromise<Array<NetworkAccount>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/network',
            path: {
                'id': id,
            },
            query: {
                'skip': skip,
                'number': number,
                'project_id': projectId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Creates a new network account.
     * @param data account to create
     * @param id ID of the business to which the network account will be linked
     * @returns NetworkAccount OK
     * @throws ApiError
     */
    public static addNetworkAccount(
        data: NetworkAccount,
        id: string,
    ): CancelablePromise<NetworkAccount> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/network',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets a network account in a user's network
     * @param id ID of the business account
     * @param networkaccountid ID of the network account to get
     * @returns NetworkAccount OK
     * @throws ApiError
     */
    public static getNetworkAccount(
        id: string,
        networkaccountid: string,
    ): CancelablePromise<NetworkAccount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/network/{networkaccountid}',
            path: {
                'id': id,
                'networkaccountid': networkaccountid,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Updates an existing network account that is un-owned.
     * @param data NetworkAccount update data
     * @param id ID of the business for which the NetworkAccount will be updated
     * @param networkaccountid ID of the NetworkAccount to update
     * @returns NetworkAccount OK
     * @throws ApiError
     */
    public static updateNetworkAccount(
        data: NetworkAccount,
        id: string,
        networkaccountid: string,
    ): CancelablePromise<NetworkAccount> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/business-account/{id}/network/{networkaccountid}',
            path: {
                'id': id,
                'networkaccountid': networkaccountid,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Adds a project on to a network object
     * @param data Project to add
     * @param id ID of the business which owns the network
     * @param networkaccountid ID of the network account to which the project will be attached
     * @returns NetworkAccount OK
     * @throws ApiError
     */
    public static addProjectToNetwork(
        data: NetworkProject,
        id: string,
        networkaccountid: string,
    ): CancelablePromise<NetworkAccount> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/network/{networkaccountid}/project',
            path: {
                'id': id,
                'networkaccountid': networkaccountid,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Removes a business from a user's network
     * @param id ID of the business for which the NetworkAccount will be deleted
     * @param networkaccountid ID of the NetworkAccount to delete from network
     * @returns DeleteResponse OK
     * @throws ApiError
     */
    public static deleteNetworkAccountV2(
        id: string,
        networkaccountid: string,
    ): CancelablePromise<DeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v2/business-account/{id}/network/{networkaccountid}',
            path: {
                'id': id,
                'networkaccountid': networkaccountid,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Adds a project on to a network object
     * @param id ID of the business which owns the network
     * @param networkaccountid ID of the network account to which the project will be attached
     * @param projectid ID of the project top remove from network
     * @returns DeleteResponse OK
     * @throws ApiError
     */
    public static removeProjectFromNetworkV2(
        id: string,
        networkaccountid: string,
        projectid: string,
    ): CancelablePromise<DeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v2/business-account/{id}/network/{networkaccountid}/project/{projectid}',
            path: {
                'id': id,
                'networkaccountid': networkaccountid,
                'projectid': projectid,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
}
