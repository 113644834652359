/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeleteResponse } from '../models/DeleteResponse';
import type { Invoice } from '../models/Invoice';
import type { InvoiceProjectResponse } from '../models/InvoiceProjectResponse';
import type { InvoicesResponse } from '../models/InvoicesResponse';
import type { JsonPdf } from '../models/JsonPdf';
import type { ManualPaymentRequest } from '../models/ManualPaymentRequest';
import type { Payment } from '../models/Payment';
import type { PublicInvoice } from '../models/PublicInvoice';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class InvoiceService {
    /**
     * Create a new manual payment
     * @param data Create a manual payment
     * @param id ID of the business account
     * @param iid ID of the invoice
     * @returns Payment OK
     * @throws ApiError
     */
    public static postManualPayment(
        data: ManualPaymentRequest,
        id: string,
        iid: string,
    ): CancelablePromise<Payment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/invoice/{iid}/manual-payment',
            path: {
                'id': id,
                'iid': iid,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Update a manual payment
     * @param data Update a manual payment
     * @param id ID of the business account
     * @param iid ID of the invoice
     * @param pid Payment-id of the manual payment
     * @returns Payment OK
     * @throws ApiError
     */
    public static putManualPayment(
        data: ManualPaymentRequest,
        id: string,
        iid: string,
        pid: string,
    ): CancelablePromise<Payment> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/business-account/{id}/invoice/{iid}/manual-payment/{pid}',
            path: {
                'id': id,
                'iid': iid,
                'pid': pid,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Delete a manual payment
     * @param id ID of the business account
     * @param iid ID of the invoice
     * @param pid Payment-id of the manual payment
     * @returns DeleteResponse OK
     * @throws ApiError
     */
    public static deleteManualPayment(
        id: string,
        iid: string,
        pid: string,
    ): CancelablePromise<DeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/business-account/{id}/invoice/{iid}/manual-payment/{pid}',
            path: {
                'id': id,
                'iid': iid,
                'pid': pid,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets all invoices/estimates for a specific business.
     * @param id ID of the business account
     * @param type Type of entity: invoice, estimate
     * @param limit Maximum number of invoices/estimates to return
     * @param page Page to view, page size is based on limit param
     * @param sort field to sort results by, include multiple times for multiple sort directions. Valid fields are: number, payor.name, project, lastupdated, status, totalamount
     * @param direction direction in which to sort fields by, include one for each field, otherwise defaults to ascending
     * @param status field to filter by invoices status, if present overrides statuses
     * @param number field to filter by invoices number
     * @param projectid field to filter by project id
     * @param statuses comma delimited list of one or more invoice/estimate statuses
     * @param totalamounts totalamount range as comma delimited list of one or two totalamounts
     * @returns InvoicesResponse OK
     * @throws ApiError
     */
    public static getInvoicesForBusinessAccount(
        id: string,
        type: string,
        limit?: number,
        page?: number,
        sort?: string,
        direction?: string,
        status?: string,
        number?: string,
        projectid?: string,
        statuses?: string,
        totalamounts?: string,
    ): CancelablePromise<InvoicesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/{type}',
            path: {
                'id': id,
                'type': type,
            },
            query: {
                'limit': limit,
                'page': page,
                'sort': sort,
                'direction': direction,
                'status': status,
                'number': number,
                'projectid': projectid,
                'statuses': statuses,
                'totalamounts': totalamounts,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Creates an invoice/estimate
     * @param data invoice to create
     * @param id ID of the business account
     * @param type Type of entity: invoice, estimate
     * @returns Invoice OK
     * @throws ApiError
     */
    public static postInvoice(
        data: Invoice,
        id: string,
        type: string,
    ): CancelablePromise<Invoice> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/{type}',
            path: {
                'id': id,
                'type': type,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets a specific invoice/estimate.
     * @param id ID of the business account
     * @param type Type of entity: invoice, estimate
     * @param iid ID of the invoice/estimate to fetch
     * @returns Invoice OK
     * @throws ApiError
     */
    public static getInvoice(
        id: string,
        type: string,
        iid: string,
    ): CancelablePromise<Invoice> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/{type}/{iid}',
            path: {
                'id': id,
                'type': type,
                'iid': iid,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Updates an existing invoice/estimate.
     * @param data Invoice update data
     * @param id ID of the business for which the invoice will be updated
     * @param type Type of entity: invoice, estimate
     * @param iid ID of the invoice/estimate to update
     * @returns Invoice OK
     * @throws ApiError
     */
    public static putInvoice(
        data: Invoice,
        id: string,
        type: string,
        iid: string,
    ): CancelablePromise<Invoice> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/business-account/{id}/{type}/{iid}',
            path: {
                'id': id,
                'type': type,
                'iid': iid,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Clone a specific invoice/estimate.
     * @param id ID of the business account
     * @param type Type of entity: invoice, estimate
     * @param iid ID of the invoice/estimate to clone
     * @returns Invoice OK
     * @throws ApiError
     */
    public static cloneInvoice(
        id: string,
        type: string,
        iid: string,
    ): CancelablePromise<Invoice> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/{type}/{iid}/clone',
            path: {
                'id': id,
                'type': type,
                'iid': iid,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Adds a project to an invoice/estimate
     * @param id ID of the requesting business account
     * @param type Type of entity: invoice, estimate
     * @param iid ID of the invoice/estimate to add the project to
     * @param pid ID of the project to add the invoice/estimate
     * @returns InvoiceProjectResponse Created
     * @throws ApiError
     */
    public static postInvoiceProject(
        id: string,
        type: string,
        iid: string,
        pid: string,
    ): CancelablePromise<InvoiceProjectResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/{type}/{iid}/project/{pid}',
            path: {
                'id': id,
                'type': type,
                'iid': iid,
                'pid': pid,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Deletes a project from an invoice/estimate
     * @param id ID of the requesting business account
     * @param type Type of entity: invoice, estimate
     * @param iid ID of the invoice/estimate to delete the project from
     * @param pid ID of the project to delete from the invoice/estimate
     * @returns DeleteResponse OK
     * @throws ApiError
     */
    public static deleteInvoiceProject(
        id: string,
        type: string,
        iid: string,
        pid: string,
    ): CancelablePromise<DeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/business-account/{id}/{type}/{iid}/project/{pid}',
            path: {
                'id': id,
                'type': type,
                'iid': iid,
                'pid': pid,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Create example invoice
     * @param data Use FullPayor to create example invoice.  ID should be populated to use the business's logo
     * @param accept accept header
     * @returns string OK
     * @returns JsonPdf Created
     * @throws ApiError
     */
    public static exampleInvoice(
        data: Invoice,
        accept?: 'application/pdf' | 'text/base64',
    ): CancelablePromise<string | JsonPdf> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/example-invoice',
            headers: {
                'Accept': accept,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Calculate invoice totals
     * @param data Calculate invoice totals
     * @returns Invoice OK
     * @throws ApiError
     */
    public static calculateInvoice(
        data: Invoice,
    ): CancelablePromise<Invoice> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/invoice-calculate',
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Get an invoice
     * @param id ID of the business account
     * @param estimateid ID of the estimate
     * @returns Invoice OK
     * @throws ApiError
     */
    public static publicGetEstimate(
        id: string,
        estimateid: string,
    ): CancelablePromise<Invoice> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/public/{id}/estimate/{estimateid}',
            path: {
                'id': id,
                'estimateid': estimateid,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Get an invoice
     * @param id ID of the business account or the url-encoded flumetag
     * @param invoiceid ID or the paymentid of the AR payment
     * @returns PublicInvoice OK
     * @throws ApiError
     */
    public static publicGetInvoice(
        id: string,
        invoiceid: string,
    ): CancelablePromise<PublicInvoice> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/public/{id}/invoice/{invoiceid}',
            path: {
                'id': id,
                'invoiceid': invoiceid,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Fetches the pdf for the given invoice/estimate.
     * @param type Type of entity: invoice, estimate
     * @param id ID of the invoice/estimate to fetch
     * @returns string OK
     * @throws ApiError
     */
    public static getInvoicePdfWithId(
        type: string,
        id: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/public/{type}/{id}/pdf',
            path: {
                'type': type,
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Fetches the pdf for the given invoice/estimate.
     * @param id ID of the invoice/estimate to fetch
     * @param type Type of entity: invoice, estimate
     * @param version ID of the invoice version to fetch
     * @returns string OK
     * @throws ApiError
     */
    public static getInvoicePdfWithIdAndVersion(
        id: string,
        type: string,
        version: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/public/{type}/{id}/pdf/{version}',
            path: {
                'id': id,
                'type': type,
                'version': version,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Generate a real-time invoice for the provided input.
     * @param data Invoice data to generate
     * @param type Type of entity: invoice, estimate
     * @returns string OK
     * @throws ApiError
     */
    public static generateInvoicePdfHandler(
        data: Invoice,
        type: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/{type}/generate-pdf',
            path: {
                'type': type,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Deletes a specific invoice/estimate.
     * @param id ID of the business account
     * @param type Type of entity: invoice, estimate
     * @param iid ID of the invoice/estimate to delete
     * @returns DeleteResponse OK
     * @throws ApiError
     */
    public static deleteInvoiceV2(
        id: string,
        type: string,
        iid: string,
    ): CancelablePromise<DeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v2/business-account/{id}/{type}/{iid}',
            path: {
                'id': id,
                'type': type,
                'iid': iid,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
}
