/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentRequest } from '../models/DocumentRequest';
import type { DocumentRequestComplete } from '../models/DocumentRequestComplete';
import type { DocumentRequestCreate } from '../models/DocumentRequestCreate';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DocumentRequestService {
    /**
     * Create a new document request
     * @param id ID of the business account
     * @param data Create a new document request
     * @returns DocumentRequest OK
     * @throws ApiError
     */
    public static postDocumentRequest(
        id: string,
        data: DocumentRequestCreate,
    ): CancelablePromise<DocumentRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/document-request',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets a specific document request.
     * @param id ID of the business account
     * @param rid ID of the document request to fetch
     * @returns DocumentRequest OK
     * @throws ApiError
     */
    public static getDocumentRequest(
        id: string,
        rid: string,
    ): CancelablePromise<DocumentRequest> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/document-request/{rid}',
            path: {
                'id': id,
                'rid': rid,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Deletes a specific document request.
     * @param id ID of the business account
     * @param rid ID of the document request to delete
     * @returns any OK
     * @throws ApiError
     */
    public static deleteDocumentRequest(
        id: string,
        rid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/business-account/{id}/document-request/{rid}',
            path: {
                'id': id,
                'rid': rid,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Mark document request as complete
     * @param id ID of the business account
     * @param rid ID of the document request to delete
     * @param data Complete a document request
     * @returns DocumentRequest OK
     * @throws ApiError
     */
    public static completeDocumentRequest(
        id: string,
        rid: string,
        data: DocumentRequestComplete,
    ): CancelablePromise<DocumentRequest> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/business-account/{id}/document-request/{rid}/complete',
            path: {
                'id': id,
                'rid': rid,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Resend a document request notification
     * @param id ID of the business account
     * @param rid ID of the document request
     * @returns DocumentRequest OK
     * @throws ApiError
     */
    public static resendDocumentRequest(
        id: string,
        rid: string,
    ): CancelablePromise<DocumentRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/document-request/{rid}/resend',
            path: {
                'id': id,
                'rid': rid,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets document requests for a business project.
     * @param id ID of the business account
     * @param pid ID of the project
     * @returns DocumentRequest OK
     * @throws ApiError
     */
    public static getDocumentRequestsByProject(
        id: string,
        pid: string,
    ): CancelablePromise<Array<DocumentRequest>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/project/{pid}/document-request',
            path: {
                'id': id,
                'pid': pid,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets a specific document request with business account.
     * @param id ID of the business account
     * @param rid ID of the document request to fetch
     * @returns DocumentRequest OK
     * @throws ApiError
     */
    public static getDocumentRequestWithBusinessAccount(
        id: string,
        rid: string,
    ): CancelablePromise<DocumentRequest> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/public/business-account/{id}/document-request/{rid}',
            path: {
                'id': id,
                'rid': rid,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Public mark document request as complete
     * @param id ID of the business account
     * @param rid ID of the document request to delete
     * @param data Complete a document request
     * @returns DocumentRequest OK
     * @throws ApiError
     */
    public static completeDocumentRequestPublic(
        id: string,
        rid: string,
        data: DocumentRequestComplete,
    ): CancelablePromise<DocumentRequest> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/public/business-account/{id}/document-request/{rid}/complete',
            path: {
                'id': id,
                'rid': rid,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
}
