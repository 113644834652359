import { useMemo } from "react";
import { RegistrationForm } from "./type";
// Query param keys
const EMAIL = "email";
const FIRST_NAME = "first_name";
const LAST_NAME = "last_name";

/**
 * Builds out the default form values object from the query params.
 * @param params the URL search params.
 * @returns the default form values.
 */
export const useRegistrationFormDefaults = (params: URLSearchParams): RegistrationForm => {
  return useMemo(
    () => ({
      email: params.has(EMAIL) ? decodeURI(params.get(EMAIL)) : "",
      firstName: params.has(FIRST_NAME) ? decodeURI(params.get(FIRST_NAME)) : "",
      lastName: params.has(LAST_NAME) ? decodeURI(params.get(LAST_NAME)) : "",
    }),
    [params.get(EMAIL), params.get(FIRST_NAME), params.get(LAST_NAME)]
  );
};
