/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type LastPayment = {
    destination?: string;
    payment_method?: LastPayment.payment_method;
    source?: string;
};
export namespace LastPayment {
    export enum payment_method {
        ACH = 'ach',
        CHECK_DIGITAL = 'check_digital',
        CHECK_PAPER = 'check_paper',
        PAY_PORTAL_ACH = 'pay_portal_ach',
        CARD = 'card',
    }
}

