/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ErpBill } from '../models/ErpBill';
import type { ErpSupplier } from '../models/ErpSupplier';
import type { NetworkAccount } from '../models/NetworkAccount';
import type { ScrubMatchRequest } from '../models/ScrubMatchRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BusinessAccountErpService {
    /**
     * Gets the unpaid bills for a business
     * @param id ID of the business account requesting the bills
     * @param refresh If true, have Codat retrieve latest bills from Quickbooks
     * @param timeoutSeconds The number of seconds to allow refresh before timeout occurs, defaults to 20
     * @param erpSupplierId Optional filter to return bills for one supplier
     * @returns ErpBill OK
     * @throws ApiError
     */
    public static getBills(
        id: string,
        refresh?: boolean,
        timeoutSeconds?: number,
        erpSupplierId?: string,
    ): CancelablePromise<Array<ErpBill>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/erp/bill',
            path: {
                'id': id,
            },
            query: {
                'refresh': refresh,
                'timeoutSeconds': timeoutSeconds,
                'erpSupplierId': erpSupplierId,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets one unpaid bills for a business
     * @param id ID of the business account requesting the bills
     * @param bid ID of the bill
     * @returns ErpBill OK
     * @throws ApiError
     */
    public static getBill(
        id: string,
        bid: string,
    ): CancelablePromise<ErpBill> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/erp/bill/{bid}',
            path: {
                'id': id,
                'bid': bid,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets the list of suppliers for a business
     * @param id ID of the business account requesting the list of their suppliers
     * @param search Search term for the list, will key on name only
     * @param getClaimed If true, will include businesses that have an associated network account.
     * @returns NetworkAccount OK
     * @throws ApiError
     */
    public static getSuppliers(
        id: string,
        search?: string,
        getClaimed?: boolean,
    ): CancelablePromise<Array<NetworkAccount>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/erp/supplier',
            path: {
                'id': id,
            },
            query: {
                'search': search,
                'getClaimed': getClaimed,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Find network accounts using supplier
     * @param id Id of the Network owners account
     * @param supplierid Id of the erp supplier to find potential matches for
     * @returns NetworkAccount OK
     * @throws ApiError
     */
    public static findNetworkAccountsFromSupplier(
        id: string,
        supplierid: string,
    ): CancelablePromise<Array<NetworkAccount>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/find-network/{supplierid}',
            path: {
                'id': id,
                'supplierid': supplierid,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Find suppliers using network account
     * @param id Id of the Network owners account
     * @param networkid Id of the network account to find potential matches for
     * @returns ErpSupplier OK
     * @throws ApiError
     */
    public static findSupplierFromNetworkAccount(
        id: string,
        networkid: string,
    ): CancelablePromise<Array<ErpSupplier>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/find-supplier/{networkid}',
            path: {
                'id': id,
                'networkid': networkid,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Creates an erp-supplier-id for a given business in a users network.
     * @param id ID of the business account that owns the network
     * @param networkaccountid ID of the network account for which the id is generated
     * @returns NetworkAccount OK
     * @throws ApiError
     */
    public static createErpEntity(
        id: string,
        networkaccountid: string,
    ): CancelablePromise<NetworkAccount> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/network/{networkaccountid}/erp',
            path: {
                'id': id,
                'networkaccountid': networkaccountid,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Performs a lookup of accounts to find potential matches for between Flume and ERP businesses
     * @param id Id of the Network owners account
     * @param sid Id of the business to find potential matches for
     * @param findType Type of account to look for
     * @param address The address to lookup. Format as: Line1+Line2+City+State+Zip+Country
     * @param search search key - will filter results
     * @returns NetworkAccount OK
     * @throws ApiError
     */
    public static scrubRecommendLookup(
        id: string,
        sid: string,
        findType: 'FindSupplier' | 'FindCustomer',
        address?: string,
        search?: string,
    ): CancelablePromise<Array<NetworkAccount>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/scrub/{sid}',
            path: {
                'id': id,
                'sid': sid,
            },
            query: {
                'address': address,
                'search': search,
                'findType': findType,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Will merge one account into another for the scrubbing workflow.
     * @param data Info for the account to match, and the network owners id
     * @returns NetworkAccount OK
     * @throws ApiError
     */
    public static matchAccount(
        data: ScrubMatchRequest,
    ): CancelablePromise<NetworkAccount> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/scrub/match',
            body: data,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets the list of suppliers for a business
     * @param id ID of the business account requesting the list of their suppliers
     * @param search Search term for the list, will key on name only
     * @param address The address to lookup. Format as: Line1+Line2+City+State+Zip+Country
     * @param getClaimed If true, will include businesses that have an associated network account.
     * @returns ErpSupplier OK
     * @throws ApiError
     */
    public static getSuppliersReturnSuppliers(
        id: string,
        search?: string,
        address?: string,
        getClaimed?: boolean,
    ): CancelablePromise<Array<ErpSupplier>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/business-account/{id}/erp/supplier',
            path: {
                'id': id,
            },
            query: {
                'search': search,
                'address': address,
                'getClaimed': getClaimed,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
}
