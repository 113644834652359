/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type InvoiceUpdateRequest = {
    estimate_status?: InvoiceUpdateRequest.estimate_status;
    id?: string;
    is_public?: boolean;
};
export namespace InvoiceUpdateRequest {
    export enum estimate_status {
        DRAFT = 'draft',
        PENDING = 'pending',
        APPROVED = 'approved',
        REJECTED = 'rejected',
        INVOICED = 'invoiced',
    }
}

