/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Document } from './Document';
import type { Invoice } from './Invoice';
import type { PublicPayor } from './PublicPayor';
export type PublicPayRequest = {
    amount: number;
    currency: string;
    documents?: Array<Document>;
    invoice?: Invoice;
    memo?: string;
    notes?: string;
    payment_date: string;
    payment_method?: PublicPayRequest.payment_method;
    payment_type?: PublicPayRequest.payment_type;
    payor: PublicPayor;
};
export namespace PublicPayRequest {
    export enum payment_method {
        CARD = 'card',
        ACH = 'ach',
    }
    export enum payment_type {
        PAYEE = 'payee',
    }
}

