import { CenterLayout } from "flume/layout/main/CenterLayout";
import { forwardRef } from "react";

/**
 * By default this component will only show itself, which is a copy of the rendered Okta widget.
 * This will also serve as a layout when the Okta widget has mounted itself but hasn't finished its own
 * render cycle which is outside of React's control.  This allows the fallback to take its place
 * until it has fully completed.
 */
export const OktaSignInFallback = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  (props, ref) => {
    return (
      <CenterLayout dark className="okta-layout-fallback">
        <div className="okta-layout-fallback-wrapper">
          <div className="okta-layout-fallback-container">
            <div className="okta-layout-fallback-header">
              <h1>
                <img
                  src="/img/wex-select-primary-logo.svg"
                  className="auth-org-logo"
                  alt=" logo"
                  aria-label=" logo"
                />
              </h1>
            </div>
            <div className="okta-layout-fallback-content">
              <div className="okta-layout-fallback-body"></div>
              <div className="okta-layout-fallback-footer">
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div ref={ref}></div>
      </CenterLayout>
    );
  }
);
