/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type InvoiceProjectResponse = {
    color?: string;
    id?: string;
    name?: string;
    status?: InvoiceProjectResponse.status;
};
export namespace InvoiceProjectResponse {
    export enum status {
        ACTIVE = 'active',
        INACTIVE = 'inactive',
    }
}

