/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckoutLink } from '../models/CheckoutLink';
import type { DeleteResponse } from '../models/DeleteResponse';
import type { FleetInvoicePayRequest } from '../models/FleetInvoicePayRequest';
import type { FleetPaymentResponse } from '../models/FleetPaymentResponse';
import type { FundRequest } from '../models/FundRequest';
import type { ManualPaymentRequest } from '../models/ManualPaymentRequest';
import type { Payment } from '../models/Payment';
import type { PaymentHistoryResponse } from '../models/PaymentHistoryResponse';
import type { PaymentRequest } from '../models/PaymentRequest';
import type { PaymentResponse } from '../models/PaymentResponse';
import type { Project } from '../models/Project';
import type { PublicPayRequest } from '../models/PublicPayRequest';
import type { UpdatePaymentRequest } from '../models/UpdatePaymentRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PaymentService {
    /**
     * Fund Flume account from external
     * @param id ID of the business account to fund
     * @param data Funding request
     * @returns PaymentResponse Created
     * @throws ApiError
     */
    public static fund(
        id: string,
        data: FundRequest,
    ): CancelablePromise<PaymentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/fund',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Send a notification for a get paid request
     * @param pid ID of the get paid to send notification for
     * @param id ID of the business requesting the notifcation resend
     * @returns Payment OK
     * @throws ApiError
     */
    public static sendGetPaidNotification(
        pid: string,
        id: string,
    ): CancelablePromise<Payment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/get-paid/{pid}/notify',
            path: {
                'pid': pid,
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Create a new manual payment
     * @param data Create a manual payment
     * @param id ID of the business account
     * @param iid ID of the invoice
     * @returns Payment OK
     * @throws ApiError
     */
    public static postManualPayment(
        data: ManualPaymentRequest,
        id: string,
        iid: string,
    ): CancelablePromise<Payment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/invoice/{iid}/manual-payment',
            path: {
                'id': id,
                'iid': iid,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Update a manual payment
     * @param data Update a manual payment
     * @param id ID of the business account
     * @param iid ID of the invoice
     * @param pid Payment-id of the manual payment
     * @returns Payment OK
     * @throws ApiError
     */
    public static putManualPayment(
        data: ManualPaymentRequest,
        id: string,
        iid: string,
        pid: string,
    ): CancelablePromise<Payment> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/business-account/{id}/invoice/{iid}/manual-payment/{pid}',
            path: {
                'id': id,
                'iid': iid,
                'pid': pid,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Delete a manual payment
     * @param id ID of the business account
     * @param iid ID of the invoice
     * @param pid Payment-id of the manual payment
     * @returns DeleteResponse OK
     * @throws ApiError
     */
    public static deleteManualPayment(
        id: string,
        iid: string,
        pid: string,
    ): CancelablePromise<DeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/business-account/{id}/invoice/{iid}/manual-payment/{pid}',
            path: {
                'id': id,
                'iid': iid,
                'pid': pid,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Create a new payment project
     * @param id ID of the business account
     * @param pid ID of the payment for which the project will be added
     * @param data Add a new project to a payment
     * @returns Payment OK
     * @throws ApiError
     */
    public static paymentProject(
        id: string,
        pid: string,
        data: Project,
    ): CancelablePromise<Payment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/payment/{pid}/project',
            path: {
                'id': id,
                'pid': pid,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Deletes a specific payment project.
     * @param id ID of the business account
     * @param pid ID of the payment from which the project will be deleted
     * @param projid ID of the project to delete
     * @returns Payment OK
     * @throws ApiError
     */
    public static deletePaymentProject(
        id: string,
        pid: string,
        projid: string,
    ): CancelablePromise<Payment> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/business-account/{id}/payment/{pid}/project/{projid}',
            path: {
                'id': id,
                'pid': pid,
                'projid': projid,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Reissue payment notification
     * @param id ID of the business account
     * @param pid ID of the payment for which to reissue notification
     * @returns Payment OK
     * @throws ApiError
     */
    public static reissuePaymentNotification(
        id: string,
        pid: string,
    ): CancelablePromise<Payment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/payment/{pid}/reissue-notification',
            path: {
                'id': id,
                'pid': pid,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Void a payment
     * @param id ID of the business account
     * @param pid ID of the payment to void
     * @returns Payment OK
     * @throws ApiError
     */
    public static voidPayment(
        id: string,
        pid: string,
    ): CancelablePromise<Payment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/payment/{pid}/void',
            path: {
                'id': id,
                'pid': pid,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Withdraw funds from your flume account, and send them to your external account
     * @param id ID of the business account from which to withdraw funds
     * @param data Withdrawal request
     * @returns PaymentResponse Created
     * @throws ApiError
     */
    public static withdraw(
        id: string,
        data: FundRequest,
    ): CancelablePromise<PaymentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/withdraw',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets a specific payment.
     * @param id ID of the payment to fetch
     * @returns Payment OK
     * @throws ApiError
     */
    public static getPayment(
        id: string,
    ): CancelablePromise<Payment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/payment/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Update the payment
     * @param data Update payment
     * @param id ID of the payment to update
     * @returns Payment OK
     * @throws ApiError
     */
    public static updatePayment(
        data: UpdatePaymentRequest,
        id: string,
    ): CancelablePromise<Payment> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/payment/{id}',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Create a new public payment
     * @param data Public payment detail
     * @param id ID of the business account or the url-encoded flumetag
     * @returns CheckoutLink OK
     * @throws ApiError
     */
    public static publicCreatePayment(
        data: PublicPayRequest,
        id: string,
    ): CancelablePromise<CheckoutLink> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/public/{id}/create-payment',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
    }
    /**
     * Pay an invoice
     * @param id ID of the business account or the url-encoded flumetag
     * @param data Public payment detail
     * @param invoiceid ID or the paymentid of the AR payment
     * @returns CheckoutLink OK
     * @throws ApiError
     */
    public static publicPayInvoice(
        id: string,
        data: PublicPayRequest,
        invoiceid: string,
    ): CancelablePromise<CheckoutLink> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/public/{id}/pay-invoice/{invoiceid}',
            path: {
                'id': id,
                'invoiceid': invoiceid,
            },
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
    }
    /**
     * Create a fleet payment
     * @param data Create a fleet payment
     * @param id ID of the business account
     * @returns FleetPaymentResponse OK
     * @throws ApiError
     */
    public static payFleet(
        data: FleetInvoicePayRequest,
        id: string,
    ): CancelablePromise<FleetPaymentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/business-account/{id}/fleet/pay',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Create a new payment
     * @param data Create a payment request
     * @param id ID of the business account
     * @returns PaymentResponse OK
     * @throws ApiError
     */
    public static payWithPayee(
        data: PaymentRequest,
        id: string,
    ): CancelablePromise<PaymentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/business-account/{id}/pay',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Deletes a specific payment project.
     * @param id ID of the business account
     * @param pid ID of the payment from which the project will be deleted
     * @param projid ID of the project to delete
     * @returns DeleteResponse OK
     * @throws ApiError
     */
    public static deletePaymentProjectV2(
        id: string,
        pid: string,
        projid: string,
    ): CancelablePromise<DeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v2/business-account/{id}/payment/{pid}/project/{projid}',
            path: {
                'id': id,
                'pid': pid,
                'projid': projid,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets all payments for a specific business.
     * @param id ID of the business account to fetch the payments of
     * @param limit Maximum number of payments to return
     * @param page Page to view, page size is based on limit param
     * @param sort field to sort results by, include multiple times for multiple sort directions. Valid fields are: date, posted_date, amount, payor.id, payee.id, payor.name, payee.name, id, status
     * @param direction direction in which to sort fields by, include one for each field, otherwise defaults to ascending
     * @param projectId comma delimited list of one or more project ids
     * @param methods comma delimited list of one or more payment methods. Valid values are: check_paper, check_digital, ach, flumepay, card
     * @param paytypes comma delimited list of one or more payment types. Valid values are: payor, payee, fund, withdraw, ar, ap, subscription, reward, card_payout
     * @param statuses comma delimited list of one or more payment statuses. Valid values are: created, issued, in_progress, funding, complete, settled, pending-settled, error, rejected, returned, void, paid, refused, canceled
     * @param amounts amount range as comma delimited list of one or two amounts
     * @param dates payment date range as comma delimited list of one or two dates
     * @param postedDates payment posted date range as comma delimited list of one or two dates
     * @param fleetcardId comma delimited list of one or more fleet card ids
     * @param payorId comma delimited list of one or more payor ids
     * @param payeeId comma delimited list of one or more payee ids
     * @param payeeName comma delimited list of one or more payee names
     * @returns PaymentHistoryResponse OK
     * @throws ApiError
     */
    public static getPaymentHistory(
        id: string,
        limit?: number,
        page?: number,
        sort?: string,
        direction?: string,
        projectId?: string,
        methods?: string,
        paytypes?: string,
        statuses?: string,
        amounts?: string,
        dates?: string,
        postedDates?: string,
        fleetcardId?: string,
        payorId?: string,
        payeeId?: string,
        payeeName?: string,
    ): CancelablePromise<PaymentHistoryResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/business-account/{id}/payments',
            path: {
                'id': id,
            },
            query: {
                'limit': limit,
                'page': page,
                'sort': sort,
                'direction': direction,
                'project_id': projectId,
                'methods': methods,
                'paytypes': paytypes,
                'statuses': statuses,
                'amounts': amounts,
                'dates': dates,
                'posted_dates': postedDates,
                'fleetcard_id': fleetcardId,
                'payor_id': payorId,
                'payee_id': payeeId,
                'payee_name': payeeName,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
            },
        });
    }
}
