import config from "../../../config";
import { Invoice, Section } from "../../../generated/openapi";
import { EstimateAction, InvoiceAction, InvoiceEventPayload, InvoiceType } from "./type";
import { Event } from "../../../shared/context/Event";
import { LineItem } from "../../../generated/openapi";
import { logSentryError } from "flume/sentry";
/**
 * Returns a label for the given invoice's type
 * @param invoice Invoice to check
 * @param capitalize Boolean for whether to capitalize the label or not
 * @param pluralize Boolean for whether to pluralize the label or not
 * @returns Label for the invoice type (optionally capitalized or pluralized)
 */
export const getInvoiceTypeLabel = ({
  invoice,
  capitalize = false,
  pluralize = false,
}: {
  invoice: Invoice;
  capitalize?: boolean;
  pluralize?: boolean;
}) => {
  if (invoice?.estimate) {
    return (capitalize ? "Estimate" : "estimate") + (pluralize ? "s" : "");
  }
  return (capitalize ? "Invoice" : "invoice") + (pluralize ? "s" : "");
};

/**
 * Gets the URL to view an invoice PDF.
 * @param invoiceId ID of the invoice to view.
 * @param type the type of PDF to view (estimate or invoice). Defaults to invoice.
 * @param version the version of the PDF to view.
 * @returns the URL of the invoice PDF.
 */
export const getInvoicePdfLink = (
  invoiceId: string,
  type: InvoiceType = "invoice",
  version?: string
) => {
  if (!invoiceId) {
    logSentryError("Invoice ID was not provided for PDF link");
  }
  return `${config.httpClient.baseUri}/v1/public/${type}/${invoiceId}/pdf${
    version ? "/" + version : ""
  }`;
};

/**
 * Opens the given invoice in a new tab in the browser.
 * @param invoiceId Id of the invoice to be opened.
 * @param type the type of PDF to view (estimate or invoice). Defaults to invoice.
 * @param version the version of the PDF to view.
 * @type the type of PDF to view (estimate or invoice). Defaults to invoice.
 */
export const viewInvoice = (invoiceId: string, type: InvoiceType = "invoice", version?: string) => {
  window.open(getInvoicePdfLink(invoiceId, type, version));
};
/**
 *
 * @param invoice Invoice object
 * @returns message to notify the user
 */
export const writePdfLinkToClipboard = (invoice: Invoice): string => {
  const url = getInvoicePdfLink(
    invoice?.id,
    invoice?.estimate ? "estimate" : "invoice",
    invoice.pdf_version
  );
  return writeLinkToClipBoard(url);
};

export const writeLinkToClipBoard = (link: string): string => {
  navigator.clipboard.writeText(link);
  return "Link copied";
};

/**
 * Creates the event body to be sent for the different types of invoice events.
 * @param invoices the Invoices to act upon.
 * @param type the type of event being fired.
 * @param additionalPayload additional parameters outside the invoice
 * @returns the event body for the given event type.
 */
export const getInvoiceActionEvent = ({
  invoice,
  invoices,
  type,
  additionalPayload = {},
}: {
  invoice?: Invoice;
  invoices?: Invoice[];
  type: InvoiceAction | EstimateAction;
  additionalPayload?: Omit<InvoiceEventPayload, "id">;
}) => {
  const event: Event<InvoiceEventPayload> = {
    name: type,
    payload: { invoice: invoice, invoices: invoices },
  };
  switch (type) {
    case InvoiceAction.UPDATE_STATUS:
      event.payload.status = additionalPayload?.status;
      event.payload.statusToUndo = additionalPayload?.statusToUndo;
      break;
    case InvoiceAction.ADD_PROJECT:
    case InvoiceAction.REMOVE_PROJECT:
      event.payload.project = additionalPayload.project;
      break;
  }
  return event;
};

export const getInvoiceType = (invoice: Invoice): InvoiceType =>
  invoice?.estimate ? "estimate" : "invoice";

export const isInvoiceDraft = (invoice: Invoice) =>
  invoice.status === Invoice.status.OPEN && !invoice.estimate;
export const isEstimateDraft = (invoice: Invoice) =>
  invoice.estimate_status === Invoice.estimate_status.DRAFT && invoice.estimate;
export const isInvoicePending = (invoice: Invoice) => invoice.status === Invoice.status.PENDING;
export const isInvoicePaid = (invoice: Invoice) => invoice.status === Invoice.status.PAID;
export const isInvoicePartialPaid = (invoice: Invoice) =>
  invoice.status === Invoice.status.PARTIAL_PAID;
export const isEstimateApproved = (invoice: Invoice) =>
  invoice.estimate_status === Invoice.estimate_status.APPROVED;
export const isEstimatePending = (invoice: Invoice) =>
  invoice.estimate_status === Invoice.estimate_status.PENDING;
export const isEstimateRejected = (invoice: Invoice) =>
  invoice.estimate_status === Invoice.estimate_status.REJECTED;
export const isEstimateInvoiced = (invoice: Invoice) =>
  invoice.estimate_status === Invoice.estimate_status.INVOICED;
export const invoiceHasPayments = (invoice: Invoice) => invoice?.payment_history?.length > 0;

const convertInvoice = (invoice: Invoice, convert: (num: number) => number) => {
  const inv = { ...invoice };
  inv.line_items = invoice?.line_items?.map((item) => ({
    ...item,
    quantity: +item.quantity,
    unit_price: convert(item.unit_price),
    unit_markup_applied: convert(item.unit_markup_applied),
    discount_amount: convert(item.discount_amount),
    discount_applied: convert(item.discount_applied),
    discount_percent: convert(item.discount_percent),
    total: convert(item.total),
    total_unit_price: convert(item.total_unit_price),
  }));
  inv.sections = invoice?.sections?.map((section) => ({
    ...section,
    line_items: section?.line_items?.map((item) => ({
      ...item,
      quantity: +item.quantity,
      unit_price: convert(item.unit_price),
      unit_markup_applied: convert(item.unit_markup_applied),
      discount_amount: convert(item.discount_amount),
      discount_applied: convert(item.discount_applied),
      discount_percent: convert(item.discount_percent),
      total: convert(item.total),
      total_unit_price: convert(item.total_unit_price),
    })),
  }));
  const fields = [
    "shipping_amount",
    "sales_tax_percent",
    "tax_applied",
    "discount_percent",
    "discount_amount",
    "discount_applied",
    "total_amount",
    "line_items_subtotal",
    "balance_due",
    "payment_request_max",
    "pending_payments",
    "settled_payments",
    "markup_applied",
    "markup_percent",
  ];

  fields.forEach((field) => (inv[field] = convert(invoice[field])));

  return inv;
};

export const invoiceToAPI = (invoice: Invoice): Invoice => {
  return convertInvoice(invoice, convertToAPI);
};
export const invoiceToUI = (invoice: Invoice): Invoice => {
  return convertInvoice(invoice, convertToUI);
};

export const convertToAPI = (num: number): number | null => (num ? Math.round(num * 100) : null);

export const convertToUI = (num: number): number | null => (num ? num / 100 : null);

export const lineItemsContainTaxableItem = (lineItems: LineItem[] | Section[]): boolean => {
  const items = lineItems?.flatMap((item) => item.line_items || item);
  return items?.some((item) => item.apply_invoice_sales_tax);
};
