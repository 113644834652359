import { DateTime } from "luxon";
import { FleetCard, Payment } from "../../generated/openapi";
import { SuspensionStatus } from "./type";
import { TransactionStatusCategory } from "./type";
import { TranslationFunction } from "flume/i18n/hook";
import { CurrencyFormatOptions, formatCurrency } from "flume/shared/format";

export const fleetCardToUI = (card: FleetCard) => ({
  ...card,
  profile: { ...card.profile, spend_per_day: card.profile?.spend_per_day / 100 },
});

export const fleetCardToAPI = (card: FleetCard) => card;
/**
 *
 * @param ccn Credit card number
 * @returns string in the format of 1234-5 regardless of length
 */
export const maskCreditCardNumber = (ccn: string) => {
  return ccn ? ccn.replace(/.*([0-9]{4})([0-9]{1})$/i, "$1-$2") : "";
};

/**
 * Cleans a business name for embossing. Limits to 20 characters and removes special characters.
 * @param name Business name to clean
 * @returns the cleaned business name.
 */
export const cleanCompanyName = (name: string) => {
  return name.replace(/[^0-9a-zA-Z\s]/g, "").slice(0, 20);
};

export const getFleetSuspensionStatus = (suspendedAt: string | null): SuspensionStatus => {
  const suspensionDateUTC = DateTime.fromISO(suspendedAt);
  const currentDateUTC = DateTime.now();
  const daysSinceSuspension = currentDateUTC.diff(suspensionDateUTC, "days").days;

  if (daysSinceSuspension >= 90) {
    return SuspensionStatus.PAST_DUE_STRIKE_2;
  } else if (daysSinceSuspension >= 10) {
    return SuspensionStatus.PAST_DUE_STRIKE_1;
  } else {
    return SuspensionStatus.NONE;
  }
};

export const mapStatusToCategory = (status: string): TransactionStatusCategory => {
  switch (status.toLowerCase()) {
    case "complete":
    case "paid":
    case "settled":
      return TransactionStatusCategory.COMPLETED;
    case "issued":
    case "in_progress":
    case "pending":
    case "approved":
      return TransactionStatusCategory.PENDING;
    case "error":
    case "canceled":
    case "rejected":
    case "refused":
    case "void":
    case "failed":
    case "returned":
    case "declined":
      return TransactionStatusCategory.DECLINED;
    default:
      return TransactionStatusCategory.UNKNOWN;
  }
};

export const getTransactionStatusText = (payment: Payment, t: TranslationFunction) => {
  const status = payment.status;
  const category = mapStatusToCategory(status);

  switch (category) {
    case TransactionStatusCategory.COMPLETED:
      return t("status.completed");
    case TransactionStatusCategory.PENDING:
      return t("status.pending");
    case TransactionStatusCategory.DECLINED:
      return t("status.declined");
    default:
      return status.charAt(0).toUpperCase() + status.slice(1);
  }
};

export const getTransactionStatusValue = (status: TransactionStatusCategory) => {
  switch (status) {
    case TransactionStatusCategory.COMPLETED:
      return "settled";
    case TransactionStatusCategory.PENDING:
      return "approved";
    case TransactionStatusCategory.DECLINED:
      return "declined";
    default:
      return status;
  }
};

export const getChipColor = (category: TransactionStatusCategory) => {
  switch (category) {
    case TransactionStatusCategory.COMPLETED:
      return "success";
    case TransactionStatusCategory.PENDING:
      return "warning";
    case TransactionStatusCategory.DECLINED:
      return "error";
    default:
      return "default";
  }
};

// If it's a pre-auth and declined, return a dash, otherwise return formatted currency string
const mDash = "—";
export const getTransactionAmount = (data: Payment, options?: CurrencyFormatOptions) =>
  data.payment_type === Payment.payment_type.FLEET_PREAUTH
    ? mDash
    : formatCurrency(data?.amount, { normalized: true, ...(options || {}) });

/**
 * Formats 13 digit fuel account number with dashes as XXXX-XX-XXXXXX-X.
 * @param number fuel account number.
 * @return formatted account number, empty string if no number provided.
 */
export const formatAccountNumber = (number: string) => {
  if (!number) return "";
  return `${number.substring(0, 4)}-${number.substring(4, 6)}-${number.substring(6, 12)}-${
    number[12]
  }`;
};
