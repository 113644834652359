/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentVerificationRequest } from '../models/DocumentVerificationRequest';
import type { IDVLinkToken } from '../models/IDVLinkToken';
import type { SessionID } from '../models/SessionID';
import type { User } from '../models/User';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AccountVerificationService {
    /**
     * Gets a specific document verification request.
     * @param dvrid ID of the document verification request to get
     * @param id ID of the business requesting
     * @returns DocumentVerificationRequest OK
     * @throws ApiError
     */
    public static getDocumentVerificationRequest(
        dvrid: string,
        id: string,
    ): CancelablePromise<DocumentVerificationRequest> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/account-verification/document-verification-request/{dvrid}',
            path: {
                'dvrid': dvrid,
                'id': id,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Uploads a document for a document request
     * @param id ID of the business uploading the document
     * @param dvrid document verification request id to complete
     * @param file Document to create
     * @param fileType Type of file being submitted
     * @returns DocumentVerificationRequest OK
     * @throws ApiError
     */
    public static postVerificationDocument(
        id: string,
        dvrid: string,
        file: Blob,
        fileType: string,
    ): CancelablePromise<DocumentVerificationRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/account-verification/document-verification-request/{dvrid}',
            path: {
                'id': id,
                'dvrid': dvrid,
            },
            formData: {
                'file': file,
                'file_type': fileType,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets all document verification requests for a business.
     * @param id ID of the business to get the document request for
     * @returns DocumentVerificationRequest OK
     * @throws ApiError
     */
    public static getAllDocumentVerificationRequests(
        id: string,
    ): CancelablePromise<Array<DocumentVerificationRequest>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/account-verification/document-verification-requests',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Create a plaid idv link token
     * @param id ID of the business account
     * @param device The device type, web or ios
     * @returns IDVLinkToken OK
     * @throws ApiError
     */
    public static createPlaidIdvLinkToken(
        id: string,
        device?: 'web' | 'ios',
    ): CancelablePromise<IDVLinkToken> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/account-verification/plaid/idv/create-token/{device}',
            path: {
                'id': id,
                'device': device,
            },
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Set the plaid idv user session
     * @param data plaid session id
     * @param id ID of the business account
     * @returns User OK
     * @throws ApiError
     */
    public static setPlaidIdvUserSession(
        data: SessionID,
        id: string,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/business-account/{id}/account-verification/plaid/idv/set-user-idv',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }
}
