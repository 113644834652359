/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProjectDocument } from './ProjectDocument';
import type { ProjectFinancials } from './ProjectFinancials';
export type Project = {
    business_account_id?: string;
    color?: string;
    created_at?: string;
    description?: string;
    documents?: Array<ProjectDocument>;
    financials?: ProjectFinancials;
    id?: string;
    last_activity_at?: string;
    last_updated_at?: string;
    name?: string;
    status?: Project.status;
};
export namespace Project {
    export enum status {
        ACTIVE = 'active',
        INACTIVE = 'inactive',
    }
}

