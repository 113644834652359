/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DigitalCheckResponse } from './DigitalCheckResponse';
import type { Document } from './Document';
import type { FleetCard } from './FleetCard';
import type { FleetInvoice } from './FleetInvoice';
import type { Invoice } from './Invoice';
import type { PaperCheckResponse } from './PaperCheckResponse';
import type { PaymentAccount } from './PaymentAccount';
import type { PaymentHistory } from './PaymentHistory';
import type { PaymentProject } from './PaymentProject';
import type { ThirdPartyPaymentResponse } from './ThirdPartyPaymentResponse';
import type { TransactionDetail } from './TransactionDetail';
export type Payment = {
    allow_project_assignment?: boolean;
    amount?: number;
    business_account_id?: string;
    code?: string;
    created_at?: string;
    currency?: string;
    date?: string;
    destination_account?: Payment.destination_account;
    digital_check_response?: DigitalCheckResponse;
    documents?: Array<Document>;
    due_date?: string;
    erp_bill_id?: string;
    error?: string;
    estimated_settlement_date_end?: string;
    estimated_settlement_date_start?: string;
    fee?: number;
    fleet_card?: FleetCard;
    fleet_invoice?: FleetInvoice;
    history?: Array<PaymentHistory>;
    id?: string;
    invoice?: Invoice;
    last_updated_at?: string;
    manual_payment_date?: string;
    memo?: string;
    notes?: string;
    paper_check_response?: PaperCheckResponse;
    payee?: PaymentAccount;
    payment_id?: string;
    payment_method?: Payment.payment_method;
    payment_response?: ThirdPartyPaymentResponse;
    payment_type?: Payment.payment_type;
    payor?: PaymentAccount;
    posted_date?: string;
    projects?: Array<PaymentProject>;
    reason?: string;
    source_account?: Payment.source_account;
    status?: string;
    transaction_details?: Array<TransactionDetail>;
};
export namespace Payment {
    export enum destination_account {
        INTERNAL = 'internal',
        EXTERNAL = 'external',
    }
    export enum payment_method {
        ACH = 'ach',
        CHECK_DIGITAL = 'check_digital',
        CHECK_PAPER = 'check_paper',
        PAY_PORTAL_ACH = 'pay_portal_ach',
        CARD = 'card',
    }
    export enum payment_type {
        PAYOR = 'payor',
        PAYEE = 'payee',
        FUND = 'fund',
        WITHDRAW = 'withdraw',
        AR = 'ar',
        AP = 'ap',
        SUBSCRIPTION = 'subscription',
        FLEET = 'fleet',
        FLEET_AUTH = 'fleet_auth',
        FLEET_PREAUTH = 'fleet_preauth',
    }
    export enum source_account {
        INTERNAL = 'internal',
        EXTERNAL = 'external',
    }
}

