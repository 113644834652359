/**
 * Sets the input with the given field name to the given value, and then disables it.
 * @param fieldName userProfile.{{fieldName}}, the name field of the input.
 * @param value the value to set the input to.
 */
export const setInputValue = (fieldName: string, value: string) => {
  const input = document.querySelector(
    `input[name="userProfile.${fieldName}"]`
  ) as HTMLInputElement | null;
  if (!input) {
    return;
  }
  input.value = value;
  // Event is needed so that Okta doesn't think the field hasn't changed and throw a validation error.
  input.dispatchEvent(new Event("input", { bubbles: true }));
  input.disabled = true;
};
