/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BeneficialOwner } from '../models/BeneficialOwner';
import type { BusinessAccount } from '../models/BusinessAccount';
import type { DeleteResponse } from '../models/DeleteResponse';
import type { IovationRequest } from '../models/IovationRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BusinessAccountOnboardingService {
    /**
     * Gets the list of beneficial owners for a business with the first 5 of SSN masked.
     * @param id ID of the business account
     * @returns BeneficialOwner OK
     * @throws ApiError
     */
    public static getBeneficialOwners(
        id: string,
    ): CancelablePromise<Array<BeneficialOwner>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/beneficial-owners',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Adds a new beneficial owner to a business-account.
     * @param id ID of the business account for whom the BO is created
     * @param data beneficial owner data to populate
     * @returns BeneficialOwner OK
     * @throws ApiError
     */
    public static createBeneficialOwner(
        id: string,
        data: BeneficialOwner,
    ): CancelablePromise<BeneficialOwner> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/beneficial-owners',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Updates a beneficial owner for a business.
     * @param id ID of the business account
     * @param beneficialownerid ID of the BO to update
     * @param data beneficial owner data to populate
     * @returns BeneficialOwner OK
     * @throws ApiError
     */
    public static updateBeneficialOwner(
        id: string,
        beneficialownerid: string,
        data: BeneficialOwner,
    ): CancelablePromise<BeneficialOwner> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/business-account/{id}/beneficial-owners/{beneficialownerid}',
            path: {
                'id': id,
                'beneficialownerid': beneficialownerid,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Mark the business account as complete
     * @param data iovation blackbox data
     * @param id ID of the business account
     * @returns BusinessAccount OK
     * @throws ApiError
     */
    public static completeBusinessAccount(
        data: IovationRequest,
        id: string,
    ): CancelablePromise<BusinessAccount> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/complete',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                401: `Unauthorized`,
                422: `Unprocessable Entity`,
            },
        });
    }
    /**
     * Deletes a beneficial owner for a business.
     * @param id ID of the business account
     * @param beneficialownerid ID of the BO to delete
     * @returns DeleteResponse OK
     * @throws ApiError
     */
    public static deleteBeneficialOwnerV2(
        id: string,
        beneficialownerid: string,
    ): CancelablePromise<DeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v2/business-account/{id}/beneficial-owners/{beneficialownerid}',
            path: {
                'id': id,
                'beneficialownerid': beneficialownerid,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }
}
