/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CatalogItem } from '../models/CatalogItem';
import type { DeleteResponse } from '../models/DeleteResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class InvoiceCatalogService {
    /**
     * Gets items from user's catalog
     * @param id ID of the requesting business account
     * @param categoryId comma delimited list of one or more category ids
     * @param search String to search for
     * @param limit number of results to return, sorted by description
     * @param skip number of results to skip, used for pagination. Ignored if number is not passed.
     * @returns CatalogItem OK
     * @throws ApiError
     */
    public static getCatalogItems(
        id: string,
        categoryId?: string,
        search?: string,
        limit?: number,
        skip?: number,
    ): CancelablePromise<Array<CatalogItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/invoice/catalog',
            path: {
                'id': id,
            },
            query: {
                'category_id': categoryId,
                'search': search,
                'limit': limit,
                'skip': skip,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Adds a line item to the invoice Catalog
     * @param data Line item to add to the Catalog
     * @param id ID of the requesting business account
     * @returns CatalogItem Created
     * @throws ApiError
     */
    public static addCatalogItem(
        data: CatalogItem,
        id: string,
    ): CancelablePromise<CatalogItem> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/invoice/catalog',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets an item from user's catalog
     * @param id ID of the requesting business account
     * @param catalogid ID of the catalog entry to get
     * @returns CatalogItem OK
     * @throws ApiError
     */
    public static getCatalogItem(
        id: string,
        catalogid: string,
    ): CancelablePromise<CatalogItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/invoice/catalog/{catalogid}',
            path: {
                'id': id,
                'catalogid': catalogid,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Updates a line item in the Catalog
     * @param data New line item data to add to the Catalog
     * @param id ID of the requesting business account
     * @param catalogid ID of the catalog entry to be deleted
     * @returns CatalogItem OK
     * @throws ApiError
     */
    public static updateCatalogItem(
        data: CatalogItem,
        id: string,
        catalogid: string,
    ): CancelablePromise<CatalogItem> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/business-account/{id}/invoice/catalog/{catalogid}',
            path: {
                'id': id,
                'catalogid': catalogid,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Deletes a line item in the Catalog
     * @param id ID of the requesting business account
     * @param catalogid ID of the catalog entry to be deleted
     * @returns DeleteResponse OK
     * @throws ApiError
     */
    public static deleteCatalogItemV2(
        id: string,
        catalogid: string,
    ): CancelablePromise<DeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v2/business-account/{id}/invoice/catalog/{catalogid}',
            path: {
                'id': id,
                'catalogid': catalogid,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
}
