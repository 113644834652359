import { isProd } from "../../config";
import { sentryLastEventId } from "../../sentry";
import { ErrorBoundaryFallbackProps } from "./ErrorBoundaryFallbackProps";
import { Fallback } from "./Fallback";
export const AppFallback = (props: ErrorBoundaryFallbackProps) => {
  const showDetails = !isProd();
  return (
    <Fallback showDetails={showDetails} {...props} eventId={sentryLastEventId || props.eventId} />
  );
};
