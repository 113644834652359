/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from './Address';
import type { Capabilities } from './Capabilities';
import type { LastPayment } from './LastPayment';
import type { NetworkProject } from './NetworkProject';
export type NetworkAccount = {
    account_category?: NetworkAccount.account_category;
    ach_account_number?: string;
    ach_routing_number?: string;
    address?: Address;
    alias?: string;
    capabilities?: Capabilities;
    contact_name?: string;
    email?: string;
    erp_customer_id?: string;
    erp_supplier_id?: string;
    external_customer_identifier?: string;
    fi_name?: string;
    flume_tag?: string;
    from_erp?: boolean;
    id?: string;
    ignore_address_match?: boolean;
    in_network?: boolean;
    individual_category?: NetworkAccount.individual_category;
    is_owned?: boolean;
    is_public?: boolean;
    last_paid?: string;
    last_payment?: LastPayment;
    matched?: boolean;
    name?: string;
    phone?: string;
    projects?: Array<NetworkProject>;
    send_invite?: boolean;
};
export namespace NetworkAccount {
    export enum account_category {
        INDIVIDUAL = 'individual',
        BUSINESS = 'business',
    }
    export enum individual_category {
        CUSTOMER = 'customer',
        EMPLOYEE = 'employee',
    }
}

