import { datadogRum } from "@datadog/browser-rum";
import cfg, { isProd } from "./config";
import env from "./config";
import { logSentryError } from "./sentry";

export const initializeDatadogRum = () => {
  try {
    if (isProd()) {
      datadogRum.init({
        applicationId: cfg.datadog.applicationId,
        clientToken: cfg.datadog.clientToken,
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: "datadoghq.com",
        service: "wex-select",
        env: cfg.env,
        version: env.version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
      });
    }
  } catch (error) {
    logSentryError(error);
  }
};
