import { OpenAPI } from "../generated/openapi";
import { tokenManager } from "../okta";
import { ApiRequestOptions } from "../generated/openapi/core/ApiRequestOptions";
import config from "../config";
import { setTransactionId } from "../sentry";

const resolveToken = async (options: ApiRequestOptions): Promise<string> => {
  const token = (await tokenManager.get("accessToken"))?.accessToken;
  return token;
};

const generateHeaders = async () => ({
  "X-Transaction-ID": setTransactionId(),
});

const noopPath = (str: string) => str;

function initApi() {
  OpenAPI.TOKEN = resolveToken;
  OpenAPI.BASE = config.httpClient.baseUri;
  OpenAPI.HEADERS = generateHeaders;
  OpenAPI.ENCODE_PATH = noopPath;
}

export { initApi };
