/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RoutingNumber } from '../models/RoutingNumber';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RoutingNumberService {
    /**
     * Retrieve an exact routing number
     * @param routingNumber routing number to retrieve
     * @returns RoutingNumber OK
     * @throws ApiError
     */
    public static publicHandleGetRoutingNumber(
        routingNumber: string,
    ): CancelablePromise<RoutingNumber> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/public/routing-number/{routing-number}',
            path: {
                'routing-number': routingNumber,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Retrieve routing numbers with matched prefix
     * @param routingNumber routing prefix to retrieve
     * @returns RoutingNumber OK
     * @throws ApiError
     */
    public static publicHandleGetRoutingNumbers(
        routingNumber: string,
    ): CancelablePromise<Array<RoutingNumber>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/public/routing-numbers/{routing-number}',
            path: {
                'routing-number': routingNumber,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Retrieve an exact routing number
     * @param routingNumber routing number to retrieve
     * @returns RoutingNumber OK
     * @throws ApiError
     */
    public static getRoutingNumber(
        routingNumber: string,
    ): CancelablePromise<RoutingNumber> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/routing-number/{routing-number}',
            path: {
                'routing-number': routingNumber,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Retrieve routing numbers with matched prefix
     * @param routingNumber routing prefix to retrieve
     * @returns RoutingNumber OK
     * @throws ApiError
     */
    public static getRoutingNumbers(
        routingNumber: string,
    ): CancelablePromise<Array<RoutingNumber>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/routing-numbers/{routing-number}',
            path: {
                'routing-number': routingNumber,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }
}
